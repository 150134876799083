import EditCategoryModal from '@/components/modals/EditCategoryModal';
import ConfirmModal from '@/components/modals/ConfirmModal';

import {
    ACTION_CREATE_CATEGORY_GROUP,
    ACTION_UPDATE_CATEGORY_GROUP,
    ACTION_GET_CATEGORY,
    ACTION_CREATE_CATEGORY,
    ACTION_UPDATE_CATEGORY,
    ACTION_DELETE_CATEGORY
} from '@/store/modules/content/content-category/action-types'
import { createNamespacedHelpers } from 'vuex';
import categoryErrorMixin from '@/mixins/content/categoryErrorMixin';

const {
    mapActions: mapContentCategoriesActions
} = createNamespacedHelpers('contentCategory');

export default {
    components: {
        EditCategoryModal,
        ConfirmModal
    },
    props: {
        contentType: String
    },
    mixins: [categoryErrorMixin],
    data() {
        return {
            validContentTypes: {
                'landing-categories': 'landing',
                'blog-categories': 'blog',
                'essay-categories': 'essay'
            },
            errorText: '',
            category: null
        }
    },
    computed: {
        getValidContentType() {
            return this.validContentTypes[this.contentType]
        }
    },
    activated() {
        this.getData()
    },
    deactivated() {
        this.errorText = ''
        this.category = null
    },
    methods: {
        ...mapContentCategoriesActions([
            ACTION_CREATE_CATEGORY_GROUP,
            ACTION_UPDATE_CATEGORY_GROUP,
            ACTION_GET_CATEGORY,
            ACTION_CREATE_CATEGORY,
            ACTION_UPDATE_CATEGORY,
            ACTION_DELETE_CATEGORY
        ]),
        async saveCategory(event) {
            try {
                const response = await this[ACTION_CREATE_CATEGORY_GROUP]({
                    path: this.getValidContentType,
                    data: {
                        title: event.name
                    }
                })
                const errors = response.response?.data?.errors
                if (!errors && !response.message) {
                    this.$bus.$emit('showSnackBar', 'Your category was saved', 'success')
                    this.$router.replace({
                        name: `content-${this.getValidContentType}-categories-edit`,
                        params: {
                            id: response.data.id
                        }
                    })
                }
                if (errors) {
                    // eslint-disable-next-line prefer-destructuring
                    this.errorText = errors.title[0]
                }
            } catch (e) {
                console.log(e)
            }
        },
        async getData() {
            const response = await this[ACTION_GET_CATEGORY]({
                path: this.getValidContentType,
                id: this.$route.params.id
            })
            this.category = response.data
        },
        async updateCategory(event) {
            try {
                const response = await this[ACTION_UPDATE_CATEGORY_GROUP]({
                    path: this.getValidContentType,
                    data: {
                        id: this.category.category_group[0].id,
                        title: event.name
                    }
                })
                const { errors = null } = response.data
                if (!errors && !response.message) {
                    await this.$bus.$emit('showSnackBar', 'Your category was updated', 'success')
                    this.$bus.$emit('hideCategoryGroupButton')
                }
                if (errors) {
                    // eslint-disable-next-line prefer-destructuring
                    this.errorText = errors.title[0]
                }
            } catch (e) {
                console.log(e)
            }
        },
        async createSubCategory({ positionId, title }) {
            try {
                const response = await this[ACTION_CREATE_CATEGORY]({
                    path: this.getValidContentType,
                    data: {
                        group_id: this.category.category_group[0].id,
                        title
                    }
                })
                const errors = response?.response?.data?.errors
                if (!errors && !response.message) {
                    await this.category.categories.splice(positionId, 1, response.data)
                    await this.$bus.$emit('showSnackBar', 'Your subcategory was saved', 'success')
                    await this.$bus.$emit('hideUpdateButton', { id: response.data.id })
                }
                if (errors) {
                    console.log(errors?.title[0])
                    // eslint-disable-next-line prefer-destructuring
                    this.setSubError(positionId, errors?.title[0] || errors?.id[0])
                }
            } catch (e) {
                console.log(e)
            }
        },
        async updateSubCategory({ id, title }) {
            try {
                await this[ACTION_UPDATE_CATEGORY]({
                    path: this.getValidContentType,
                    data: {
                        group_id: this.category.category_group[0].id,
                        id,
                        title
                    }
                })
                this.$bus.$emit('showSnackBar', 'Your subcategory was updated', 'success')
                this.$bus.$emit('hideUpdateButton', { id })
            } catch (e) {
                console.log(e)
            }
        },
        async deleteSubCategory({ positionId, id }) {
            try {
                if (id) {
                    await this[ACTION_DELETE_CATEGORY]({
                        path: this.getValidContentType,
                        data: {
                            group_id: this.category.category_group[0].id,
                            id
                        }
                    })
                    this.$bus.$emit('showSnackBar', 'Your subcategory was deleted', 'success')
                }
                if (this.category.categories[positionId]) {
                    this.category.categories.splice(positionId, 1)
                }
            } catch (e) {
                console.log(e)
            }
        }
    }
}
