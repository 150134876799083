<template>
    <modal-layout
        v-if="dialog"
        :name="name"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="cancelModal()"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>Update category</h2>
        </template>
        <template #content>
            <new-category
                v-model="$v.category.$model"
                :field.sync="$v.category.$model"
                :errors="validationMessage($v.category)"
                :is-valid="$v.category.$dirty && !$v.category.$anyError"
                @input.native="$v.category.$touch()"
                @blur.native="$v.category.$touch()"
            />
            <new-sub-category
                v-if="category.subcategories"
                @add-sub-category="AddSubCategory($event)"
            />
        </template>
        <template #buttons>
            <custom-button
                default
                @on-btn-click="validate"
            >
                Update
            </custom-button>
            <custom-button
                default
                @on-btn-click="cancelModal(name)"
            >
                Cancel
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { validationCategoryRules } from '@/validation/CategoryModal/Rules'
import { formMessages } from '@/validation/CategoryModal/Messages'
import NewCategory from '@/components/create-category/components/NewCategory';
import NewSubCategory from '@/components/create-category/components/NewSubCategory';
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'

export default {
    name: 'EditCategoryModal',
    validations: {
        ...validationCategoryRules
    },
    components: {
        NewCategory,
        NewSubCategory
    },
    mixins: [
        validationMixin,
        formValidationMixin,
        modalsEventBusMixins
    ],
    props: {
        name: String
    },
    data() {
        return {
            category: '',
            categoryObj: {
                title: ''
            }
        }
    },
    watch: {
        category(val) {
            this.categoryObj.title = val
        },
        data(val) {
            this.categoryObj = { ...val }
            this.category = val.title
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        submit() {
            this.dialog = false
            this.$emit('on-save-btn-click', {
                data: this.categoryObj
            })
            this.reset()
        },
        AddSubCategory(event) {
            this.subcategories.push(event)
        },
        reset() {
            this.categoryObj = {
                title: ''
            }
        }
    }
}
</script>

<style scoped>

</style>
